.top-nav-tooltip-container {
  padding-top: 0;
}

.top-nav-tooltip-container .ant-tooltip-arrow {
  display: none;
}

.top-nav-tooltip-container .ant-tooltip-inner {
  padding: 32px;
  background: #ffffff;
  box-shadow: rgba(141, 150, 170, 0.4) 0px 1px 6px 0px;
  width: 426px;
  color: #424242;
}

.mt-16 {
	margin-top: 16px;
}

.header-download__content {
	display: flex;
	text-align: center;
	-webkit-box-pack: center;
	justify-content: center;
	font-size: 14px;
	gap: 15px;
}

.header-download__right {
  gap: 5px;
  display: flex;
  flex-direction: column;
}

.header-download__left {
	max-width: 148px;
}

.header-download__left img {
	width: 100%;
	padding: 5px;
	border-radius: 10px;
	border: 1px solid #43a047;
	display: block;
	height: auto;
}

